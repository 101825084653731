<template>
  <div class="relative  min-h-screen w-screen">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'AppLayoutBasic',
};
</script>
