import {
  fetchEstablishments,
  fetchThirdParties,
  fetchThirdPartyById,
  updateThirdParty,
} from '@/api/third-party';
import { useAppStore } from '@/stores/app';
import { klona } from 'klona/json';
import localForage from 'localforage';
import { sampleSize } from 'lodash-es';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
};

const cache = localForage.createInstance({
  name: 'YaxoApp',
});
export const useThirdPartyStore = defineStore('third-party', () => {
  const appStore = useAppStore();

  const { thirdPartyTypes } = storeToRefs(appStore);

  const thirdParties = ref([]);
  const thirdParty = ref(null);

  function setNestedProperty(obj, dotNotation, value) {
    const keys = dotNotation.split('.');

    // Iterate over the keys to ensure we can access/create nested objects
    keys.reduce((acc, key, index) => {
      // If it's the last key, set the value
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        // If the property doesn't exist, create an empty object
        if (!acc[key]) {
          acc[key] = {};
        }
        return acc[key];
      }
    }, obj);
  }

  function isDotNotation(str) {
    // Matches any string that contains one or more dots between words/characters
    return /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)+$/.test(str);
  }

  /*function convertDotToBracket(dotNotation) {
    return dotNotation.split('.').map(part => `['${part}']`);
  }*/

  const setState = (path, value) => {
    if (!isDotNotation(path)) {
      thirdParty.value[path] = value;
    } else {
      setNestedProperty(thirdParty.value, path, value);
    }
  };

  const getThirdParties = async (params, payload = null) => {
    let response = await fetchThirdParties(params, payload);

    if (!response) {
      throw new Error('Failed to load thirdParty statuses list');
    }

    const rawItems = response?.data ?? [];

    const items = rawItems.map(item => {
      const typeObj = thirdPartyTypes.value.find(({ id }) => id === item.thirdPartyType.id);
      item.thirdPartyType = typeObj ?? null;
      if (item?.thirdPartyType ?? false) {
        if (!item.thirdPartyType?.severity) {
          item.thirdPartyType.severity = 'info';
        }
      }

      const lastAlert = item?.lastAlerts?.[0] ?? null;

      item.lastAlert = lastAlert;
      item.statusId = item?.status?.id ?? null;
      const tierTypesArr = klona(thirdPartyTypes.value);
      item.tierType = sampleSize(tierTypesArr, getRandomNumber(1, 3));
      return item;
    });
    response.data = items;
    thirdParties.value = response;
    return response;
  };

  const getThirdPartyById = async id => {
    let item = await fetchThirdPartyById(id);
    if (!item) {
      throw new Error('Failed to load third party statuses list');
    }
    item.statusId = item?.status?.id ?? null;

    return item;
  };

  const patchThirdParty = async (id, payload) => {
    const response = await updateThirdParty(id, payload);
    return response;
  };

  const getEstablishmentsByIdentification = async (q = '', scope = 'SIRET-TVA') => {
    if (false === !!q.trim()) {
      throw new Error('q is empty');
    }
    let response = await fetchEstablishments(q, scope);

    if (!response) {
      throw new Error('Failed to load establishments');
    }

    return response?.data ?? [];
  };

  return {
    setState,
    thirdParties,
    thirdParty,
    getThirdParties,
    getThirdPartyById,
    patchThirdParty,
    getEstablishmentsByIdentification,
  };
});
