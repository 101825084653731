//import { useUserStore } from "@/stores/user";
import { boolean } from "boolean";
export default function ({ next }) {
  const isDev = boolean(process.env.NODE_ENV === "development");
  if (isDev) {
    //return next();
  }
  /*const userStore = useUserStore();
  if (!userStore.userInfo) {
    return next({
      name: "Login",
    });
  }*/

  return next();
}
