import axiosInstance from '@/utils/axios-instance';
// import { sleep } from '@/utils/sleep';

export async function fetchThirdParties(params = {}, payload = {}) {
  const { page = 1, pageSize = 10, endpoint = '' } = params;
  const data = {
    sortField: 'companyName',
    sortOrder: 1,
    ...payload,
  };
  return axiosInstance({
    url: `third-parties/search?page=${page}&pageSize=${pageSize}&endpoint=${endpoint}`,
    method: 'post',
    data,
  });
}

export async function fetchThirdPartyById(id) {
  return axiosInstance({
    url: `third-parties/${id}`,
    method: 'get',
  });
}

export async function updateThirdParty(id, payload) {
  return axiosInstance({
    url: `third-parties/${id}`,
    method: 'patch',
    data: payload,
  });
}

export async function fetchEstablishments(q, scope = 'siret') {
  return axiosInstance({
    url: `etablissments/rne`,
    method: 'get',
    params: { q, scope },
  });
}

export async function fetchAdditionalDataApi(thirdPartyTypeSlug = 'societe', modelTagSlugs = []) {
  return axiosInstance({
    url: `third-parties/aditionnals-data/${thirdPartyTypeSlug}`,
    method: 'get',
    params: { modelTagSlugs },
    paramsSerializer: params => {
      return Object.keys(params)
        .map(key => {
          if (Array.isArray(params[key])) {
            return params[key].map(val => `${key}=${val}`).join('&');
          }
          return `${key}=${params[key]}`;
        })
        .join('&');
    },
  });
}

export async function storeThirdParty(payload = null) {
  if (!payload) {
    throw new Error('Invalid payload');
  }
  const data = {
    ...payload,
  };
  return axiosInstance({
    url: `third-parties`,
    method: 'post',
    data,
  });
}
