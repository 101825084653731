import router from '@/router';
import { createPinia } from 'pinia';
import { markRaw } from 'vue';
export const initPinia = () => {
  const pinia = createPinia();
  pinia.use(context => {
    const storeId = context.store.$id;
    const serializer = {
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    };
    const fromStorage = serializer.deserialize(localStorage.getItem(storeId));
    if (fromStorage) {
      if ('app' === storeId) {
        if (fromStorage.hasOwnProperty('isSideBarOpened')) {
          fromStorage.isSideBarOpened = false;
        }
      }
      if ('modal' === storeId) {
        if (fromStorage.hasOwnProperty('isOpen')) {
          fromStorage.isOpen = false;
        }
      }

      context.store.$patch(fromStorage);
    }
    context.store.$subscribe((mutation, state) => {
      if (['app', 'modal'].includes(storeId)) {
        localStorage.setItem(storeId, serializer.serialize(state));
      }
    });
  });
  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });
  return pinia;
};
