import axiosInstance from '@/utils/axios-instance';
export function getCountriesAPI() {
  return axiosInstance({
    url: '/countries',
    method: 'get',
  });
}

export function getSettingsAPI() {
  return axiosInstance({
    url: '/settings',
    method: 'get',
  });
}
