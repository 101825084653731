<script setup>
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import AppLayoutBasic from '@/layouts/AppLayoutBasic.vue';
import { markRaw, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const layout = ref(null);
const route = useRoute();
watch(
  () => route.meta?.layout,
  async metaLayout => {
    try {
      const component = metaLayout && (await import(`@/layouts/${metaLayout}.vue`));
      if (component?.default ?? false) {
        layout.value = markRaw(component?.default);
      }
    } catch (e) {
      console.error(e);
      layout.value = markRaw(AppLayoutBasic);
    }
  },
  { immediate: true }
);
onMounted(() => {});
</script>

<template>
  <div v-if="layout">
    <component :is="layout">
      <slot />
    </component>
  </div>
</template>
