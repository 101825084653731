import { init } from '@/inits';
import { useAppStore } from '@/stores/app';
import { useThirdPartyStore } from '@/stores/third-party';

import './style.css';

document.addEventListener('DOMContentLoaded', function () {
  (async () => {
    await init();
    const appStore = useAppStore();
    const thirdPartyStore = useThirdPartyStore();
    appStore.loading = true;
    Promise.allSettled([appStore.getGlobalSettings()]).then(results => {
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          // console.log(`Data ${index + 1}:`, result.value);
        } else {
          console.error(`Error fetching data ${index + 1}:`, result.reason);
        }
      });
      appStore.loading = false;
    });
  })();
});
